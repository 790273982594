import React, { createContext, FunctionComponent, useState } from 'react';
import Store from "../models/Store";
import SearchEndpoint from '../requests/SearchEndPoint';

interface OwnProps {

}

interface ISearchContext {
    searchResults: SearchResults | undefined,
    getResults: (queryString: string) => Promise<void>
}

interface FilterMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number
}

export interface SearchResults {
    data: Store[],
    meta: FilterMeta
    storeOfTheMonth: Store
}

const searchEndpoint = new SearchEndpoint();

export const SearchContext = createContext({} as ISearchContext);

type Props = OwnProps;

const SearchContextProvider: FunctionComponent<Props> = (props) => {
    const [searchResults, setSearchResults] = useState<SearchResults>();

    const getResults = async (queryString: string): Promise<void> => {
        // @ts-ignore
        const searchResults: SearchResults = await searchEndpoint.getSearch(queryString);
        setSearchResults(searchResults)
    }

    return (
        <SearchContext.Provider
            value={{ searchResults: searchResults, getResults }}>
            {props.children}
        </SearchContext.Provider>
    )
}

export default SearchContextProvider;
