import React, {useContext, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import ReactPaginate from 'react-paginate';
import {useHistory, useLocation} from 'react-router-dom';
import {ReactComponent as CloseIcon, ReactComponent as Close} from "../../assets/icons/close.svg";
import {ReactComponent as FilterIcon} from "../../assets/icons/Filter_sorteer.svg";
import {CityContext} from '../../contexts/CityContext';
import {SearchContext} from '../../contexts/SearchContext';
import City from '../../models/City';
import Breadcrumb from "../utils/Breadcrumb";
import SortDropdown from '../utils/SortDropdown';
import CityImage from './city-overview-components/CityImage';
import FilterMenu from './city-overview-components/FilterMenu';
import CityOverviewStore from './city-overview-components/CityOverviewStore';
import StoreOfTheMonth from './city-overview-components/StoreOfTheMonth';
import {FiltersMap} from '../utils/Filters';
import {Filter} from '../../models/enums/Filter';

interface IProps {

}

export class Sort {
    sorteer_op: string | undefined
    sorteer_richting: string | undefined
}

const sortDirections: Record<string, string> = {
    "afstand": "oplopend",
    "naam": "oplopend",
    "beoordeling": "aflopend"
};

const getCoordinatesAsync = () => {
    return new Promise<{ latitude: number, longitude: number }>((resolve) => {
        navigator.geolocation.getCurrentPosition(
            position => resolve({latitude: position.coords.latitude, longitude: position.coords.longitude})
        )
    })
}

/**
 * @component
 * @author Sem van Koutrik
 * @interface IProps
 */
const CityOverview: React.FC<IProps> = () => {
    const location = useLocation();
    const history = useHistory();

    let filters: FiltersMap = FiltersMap.fromQueryString(location.search)

    const {searchResults, getResults} = useContext(SearchContext);
    const {cities} = useContext(CityContext);

    const [city, setCity] = useState<City>();

    window.scrollTo({top: 0, left: 0})

    const initializeFilters = async () => {
        if (cities.length === 0) return

        filters = FiltersMap.fromQueryString(location.search)

        let sortOn = filters.get(Filter.sorteer_op.toString())
        let page = filters.get(Filter.page.toString())

        window.navigator.permissions.query({name: "geolocation"}).then((result) => {
            if (result.state === 'granted') {
                if (sortOn === undefined) {
                    filters
                        .set(Filter.sorteer_op.toString(), "afstand")
                        .set(Filter.sorteer_richting.toString(), "oplopend")
                } else if ((sortOn[0] === "naam")) {
                    filters
                        .set(Filter.sorteer_op.toString(), "naam")
                        .set(Filter.sorteer_richting.toString(), sortDirections[sortOn[0]])
                }
            } else {
                if (sortOn === undefined || sortOn[0] === "afstand") {
                    filters
                        .set(Filter.sorteer_op.toString(), "naam")
                        .set(Filter.sorteer_richting.toString(), "oplopend")
                } else if (sortOn[0] === "beoordeling") {
                    filters
                        .set(Filter.sorteer_op.toString(), "beoordeling")
                        .set(Filter.sorteer_richting.toString(), sortDirections[sortOn[0]])
                }
            }
            refresh()
        })

        if (page === undefined) filters.set(Filter.page.toString(), "1")

        sortOn = filters.get(Filter.sorteer_op.toString());

        if (sortOn !== undefined && sortOn[0] === "afstand"
            && (
                filters.get(Filter.latitude.toString()) === undefined
                && filters.get(Filter.longitude.toString()) === undefined
            )
        ) {
            await getCoordinatesAsync().then((coords) => {
                filters.set(Filter.latitude.toString(), coords.latitude.toString())
                filters.set(Filter.longitude.toString(), coords.longitude.toString())
            });
        }

        let queryString = filters.toQueryString()

        if (location.search !== queryString) {
            history.replace({search: queryString})
            return
        }

        let citiesInFilter = filters.get("stad")


        if (citiesInFilter !== undefined && citiesInFilter.length === 1) {
            let city = cities.find(c => c.name.toLowerCase().replace("-", " ") === citiesInFilter![0].toLowerCase().replace("-", " "))

            setCity(city)
        }

        getResults(queryString)
    }

    const refresh = () => {
        let queryString = filters.toQueryString()
        if (location.search !== queryString) {
            history.replace({search: queryString})
        }
    }

    const toggleFilter = (key: string, value: string) => {
        filters.toggle(key, value)
        filters.set(Filter.page.toString(), "1")
        refresh()
    }

    const getSort: () => Sort = () => {
        let sortDirection = filters.get(Filter.sorteer_richting.toString()) ? filters.get(Filter.sorteer_richting.toString())![0] : undefined
        let sortOn = filters.get(Filter.sorteer_op.toString()) ? filters.get(Filter.sorteer_op.toString())![0] : undefined

        return {sorteer_op: sortOn, sorteer_richting: sortDirection}
    }

    const setSort = (sort: Sort) => {
        filters
            .set(Filter.sorteer_richting.toString(), sort.sorteer_richting!)
            .set(Filter.sorteer_op.toString(), sort.sorteer_op!)
        refresh()
    }

    const setCurrentPage = (pageNumber: number) => {
        filters.set(Filter.page.toString(), pageNumber.toString())
    }

    const setCurrentPageAndRefresh = (pageNumber: number) => {
        filters.set(Filter.page.toString(), (pageNumber + 1).toString())
        refresh()
    }

    const clearAll = () => {
        filters.clear()
        refresh()
    }

    const clearFilter = (key: string) => {
        filters.delete(key)
        refresh()
    }

    const areFiltersEmpty: () => boolean = () => {
        let keys = filters.keys();
        let result = keys.next();

        while (!result.done) {
            let value = result.value;
            if (
                value !== Filter.sorteer_op.toString() &&
                value !== Filter.sorteer_richting.toString() &&
                value !== Filter.page.toString() &&
                value !== Filter.latitude.toString() &&
                value !== Filter.longitude.toString()
            ) {
                return true
            }

            result = keys.next()
        }

        return false
    }

    const mqDesktop = window.matchMedia('(min-width: 960px)');
    const filterMenuRef = useRef<HTMLDivElement>(null);
    const filterButtonRef = useRef<HTMLButtonElement>(null);
    const mobileFilterMenu = useRef<HTMLDivElement>(null);
    const mobileFilterMenuOverlay = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        mobileFilterMenu.current?.classList.toggle("open");
        mobileFilterMenuOverlay.current?.classList.toggle("open");
    }

    const hideMenu = () => {
        mobileFilterMenu.current?.classList.remove("open");
        mobileFilterMenuOverlay.current?.classList.remove("open");
    }

    useEffect(() => {
        initializeFilters();
    }, [location.search, cities])

    useEffect(() => {
        let cityFilter = filters.get(Filter.stad.toString())

        if (cityFilter === undefined || cityFilter.length > 1) setCity(undefined)
    }, [filters])

    return (
        <>
            <Helmet>
                <title>{
                    city
                        ? `Winkelen in ${city.name}. Ontdek de meest leuke fashionwinkels en winkelstraten in ${city.name} - Fashion Hotspots`
                        : filters.get(Filter.merken.toString()) && filters.get(Filter.merken.toString())?.length === 1
                            ? `Hier vind je alle fashionwinkels waar ze "${filters.get(Filter.merken.toString())![0]}" verkopen`
                            : "Stadsoverzicht en filters."
                }
                </title>
                <meta name="description" content={
                    city
                        ? `Ontdek de meest leuke fashionwinkels en boutiques in de mooiste winkelstraten van ${city.name} en profiteer van exclusieve aanbiedingen in de winkel.`
                        : "Met FashionHotspots ontdek je met 1 klik alle fashionwinkels met jouw favoriete merken"
                }/>
                <meta name="keywords" content="stadsoverzicht, kledingwinkels, stadfilter, merkenfilter"/>
            </Helmet>

            <Breadcrumb tiers={[{url: '/', name: 'Home'}, {url: '/stad-overzicht', name: 'Winkels'}]}/>

            <div className="city-overview">
                {
                    !mqDesktop.matches &&
                    <>
                        <div className="city-overview__mobile-overlay" ref={mobileFilterMenuOverlay}/>
                        <div className="city-overview__filter-menu-wrapper" ref={mobileFilterMenu}>
                            <div className="city-overview__filter-menu-header">
                                <p>filter &amp; sorteer</p>

                                {areFiltersEmpty() &&
                                    <button onClick={() => clearAll()} className="filter-menu__restore-button">Herstel
                                        alles <Close/></button>}

                                <CloseIcon id="close" onClick={toggleMenu}/>
                            </div>

                            <FilterMenu
                                filters={filters}
                                toggleFilter={toggleFilter}
                                setCurrentPage={setCurrentPage}
                                getSort={getSort}
                                setSort={setSort}
                                hideMenu={hideMenu}
                                reference={filterMenuRef}
                                clearAll={clearAll}
                                areFiltersEmpty={areFiltersEmpty}
                                clearFilter={clearFilter}
                            />
                        </div>
                    </>
                }

                <CityImage city={city}/>

                <div className="city-overview__results">
                    <StoreOfTheMonth searchResults={searchResults} store={searchResults?.storeOfTheMonth}/>

                    <div className="city-overview__results-header">
                        <p>{`${(searchResults ? searchResults?.meta.total : "Geen")} winkels gevonden`}</p>

                        {
                            mqDesktop.matches &&
                            <SortDropdown setSort={setSort}/>
                        }

                        {
                            !mqDesktop.matches &&
                            <button onClick={toggleMenu} className="filter-button" ref={filterButtonRef}>
                                <FilterIcon/> filter &amp; sorteer
                            </button>
                        }
                    </div>

                    <div className="city-overview__desktop-wrapper">
                        {
                            mqDesktop.matches &&
                            <FilterMenu
                                filters={filters}
                                toggleFilter={toggleFilter}
                                setCurrentPage={setCurrentPage}
                                getSort={getSort}
                                setSort={setSort}
                                hideMenu={hideMenu}
                                reference={filterMenuRef}
                                clearAll={clearAll}
                                areFiltersEmpty={areFiltersEmpty}
                                clearFilter={clearFilter}
                            />
                        }

                        <div className="city-overview__pages">
                            <div className="city-overview__stores">
                                {
                                    searchResults?.data.map(store =>
                                        <CityOverviewStore store={store} key={store.id} dummy={store.dummy}/>
                                    )
                                }
                            </div>

                            {
                                searchResults?.meta.last_page !== 1 &&
                                <ReactPaginate
                                    previousLabel={mqDesktop.matches ? 'vorige' : '<'}
                                    nextLabel={mqDesktop.matches ? 'volgende' : '>'}
                                    breakLabel={'...'}
                                    initialPage={filters.get("page") ? parseInt(filters.get("page")![0]) - 1 : 0}
                                    forcePage={filters.get("page") ? parseInt(filters.get("page")![0]) - 1 : 0}
                                    breakClassName={'break-me'}
                                    pageCount={searchResults ? searchResults.meta.last_page : 0}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    onPageChange={item => setCurrentPageAndRefresh(item.selected)}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CityOverview
